<template>
    <div class="dish-total">
        <dish-total-settings />
        <spinner v-if="status === 'loading'" class="dish-total__spinner" />
        <error-message v-else-if="status === 'error'" class="dish-total__error-message">
            Не удалось загрузить данные отчета "Блюда".
        </error-message>
        <section v-else-if="status === 'success'" class="dish-total__data">
            <h2 class="dish-total__data-heading sr-only">Данные отчета "Блюда"</h2>
            <dish-base-table v-if="modeId === 'base'"
                :columns="columns"
                :totalRow="baseReportTotal"
                :rows="reportRestaurants"
            />
            <template v-else-if="modeId === 'compareOtherPeriod'">
                <p class="dish-total__error" v-if="!compareDish">Выберите период сравнения.</p>
                <template v-else>
                    <dish-compare-table
                        :columns="columns"
                        :reportRow="compareReportTotal"
                        :compareRow="compareTotal"
                    />
                    <dish-compare-table
                        v-for="restaurant in reportRestaurants"
                        :key="restaurant.id"
                        :columns="columns"
                        :reportRow="restaurant"
                        :compareRow="findCompareRestaurant(compareRestaurants, restaurant)"
                    />
                </template>
            </template>
        </section>
    </div>
</template>

<script>
    import { mapActions, mapMutations, mapState } from "vuex";
    import { daterangeKey, isDaterangeSet, daterangeText } from "@/helpers/daterange";
    import { compareStrings, compareNumbers } from "@/helpers/compare";
    import DishTotalSettings from "./dish-total/DishTotalSettings";
    import DishBaseTable from "./common/DishBaseTable";
    import DishCompareTable from "./common/DishCompareTable";

    export default{
        name: "DishTotal",
        components: {
            DishTotalSettings,
            DishBaseTable,
            DishCompareTable,
        },
        props: {
            userRestaurants: {
                required: true
            }
        },
        data() {
            return {
                status: "loading",
            };
        },
        computed: {
            ...mapState({
                reportDaterange: state => state.report.daterange,
                compareDaterange: state => state.dish.compareDaterange,
                modeId: state => state.dish.modeId,
                sorting: state => state.dish.sorting,
                data: state => state.dish.data.groups
            }),
            requiredDateranges() {
                let dateranges = [this.reportDaterange];

                if (this.modeId === "compareOtherPeriod" && isDaterangeSet(this.compareDaterange)) {
                    dateranges.push(this.compareDaterange);
                }

                return dateranges;
            },
            requiredDishes() {
                return this.requiredDateranges.map(daterange => this.data[daterangeKey(daterange)]);
            },
            reportDish() {
                return this.data?.[daterangeKey(this.reportDaterange)];
            },
            compareDish() {
                if (isDaterangeSet(this.compareDaterange)) {
                    return this.data[daterangeKey(this.compareDaterange)];
                }
            },
            reportTotal() {
                return this.reportDish?.total;
            },
            baseReportTotal() {
                return {
                    ...this.reportTotal,
                    title: "Все рестораны",
                    link: {
                        to: {
                            name: "DishRestaurant",
                            params: { id: "total" }
                        },
                        title: `Смотреть блюда по всем ресторанам`
                    }
                };
            },
            compareReportTotal() {
                return {
                    ...this.reportTotal,
                    title: daterangeText(this.reportDaterange),
                    caption: "Все рестораны",
                    link: {
                        to: {
                            name: "DishRestaurant",
                            params: { id: "total" }
                        },
                        title: `Смотреть блюда по всем ресторанам`
                    }
                };
            },
            reportRestaurants() {
                return this.reportDish?.restaurants?.map(restaurant => {
                    let props = {
                        link: {
                            to: {
                                name: "DishRestaurant",
                                params: { id: restaurant.id }
                            },
                            title: `Смотреть блюда ресторана ${restaurant.title}`
                        }
                    }

                    if (this.modeId === 'compareOtherPeriod') {
                        props.title = daterangeText(this.reportDaterange);
                        props.caption = restaurant.title;
                    }

                    return {
                        ...restaurant,
                        ...props
                    };
                })?.sort((restaurant1, restaurant2) => {
                    if (this.sorting.field === "title") {
                        return compareStrings(
                            restaurant1.title,
                            restaurant2.title,
                            this.sorting.direction
                        );
                    }

                    return compareNumbers(
                        restaurant1[this.sorting.field],
                        restaurant2[this.sorting.field],
                        this.sorting.direction
                    );
                });
            },
            compareTotal() {
                return {
                    ...this.compareDish?.total,
                    title: daterangeText(this.compareDaterange),
                };
            },
            compareRestaurants() {
                return this.compareDish?.restaurants?.map(restaurant => {
                    if (this.modeId === 'compareOtherPeriod') {
                        return {
                            ...restaurant,
                            title: daterangeText(this.compareDaterange)
                        };
                    }

                    return restaurant;
                })
            },
            columns() {
                return [
                    {
                        id: "title",
                        title: this.modeId === "base" ? "Ресторан" : "Период",
                        alignment: "left",
                    },
                    {
                        id: "amount",
                        title: "Количество",
                        alignment: "right",
                        filter: "intFormat",
                        difference: {
                            good: "positive"
                        },
                    },
                    {
                        id: "sum",
                        title: "Сумма",
                        alignment: "right",
                        filter: "currencyIntFormat",
                        difference: {
                            good: "positive"
                        },
                    },
                    {
                        id: "discount",
                        title: "Сумма со скидкой",
                        alignment: "right",
                        filter: "currencyIntFormat",
                        difference: {
                            good: "positive"
                        },
                    },
                    {
                        id: "cost",
                        title: "Себестоимость",
                        alignment: "right",
                        filter: "currencyIntFormat",
                        difference: {
                            good: "negative"
                        },
                    },
                ];
            }
        },
        methods: {
            ...mapMutations([
                "setDishSorting",
            ]),
            ...mapActions([
                "requestDishes"
            ]),
            findCompareRestaurant(compareRestaurants, reportRestaurant) {
                let compareRestaurant =  compareRestaurants?.find(({ id }) =>id === reportRestaurant.id);

                if (typeof compareRestaurant === "undefined") {
                    return {
                        title: reportRestaurant.title
                    }
                }

                return compareRestaurant;
            },
            showReport() {
                this.status = "success";
            },
            loadDishes() {
                this.status = "loading";

                let daterangesWithoutData = this.requiredDateranges.filter(
                    daterange => !Boolean(this.data[daterangeKey(daterange)])
                );

                this.requestDishes({
                    dateranges: daterangesWithoutData,
                    itemsId: "groups"
                }).then(() => {
                    this.showReport();
                }).catch(() => {
                    this.status = "error";
                });
            },
        },
        created() {
            if (this.userRestaurants?.ids?.length === 1) {
                this.$router.push({
                    name: "DishRestaurant",
                    params:{
                        id: this.userRestaurants?.ids[0]
                    }
                });
            } else {
                if (this.sorting.field === "dish_group") {
                    this.setDishSorting("amount");
                }

                if (this.requiredDishes.every(Boolean)) {
                    this.showReport();
                } else {
                    this.loadDishes();
                }
            }
        },
        watch: {
            requiredDishes(requiredDishes) {
                if (!requiredDishes.every(Boolean)) {
                    this.loadDishes();
                }
            }
        }
    }
</script>

<style lang="scss">
    .dish-total__data {
        display: grid;
        grid-gap: 16px;
    }
</style>
