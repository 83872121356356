<template>
    <section class="dish-total__settings">
        <h2 class="dish-total__settings-heading sr-only">Настройки отчета "Блюда"</h2>
        <form class="dish-total__form" @submit.prevent>
            <fieldset class="dish-total__fieldset dish-total__fieldset_dateranges">
                <daterange-picker class="dish-total__daterange"
                    v-model="reportDaterangeModel"
                    name="reportDaterange"
                >
                    Период отчета:
                </daterange-picker>
                <daterange-picker class="dish-total__daterange"
                    v-if="modeId === 'compareOtherPeriod'"
                    v-model="compareDaterangeModel"
                    name="compareOtherPeriod"
                >
                    Период сравнения:
                </daterange-picker>
            </fieldset>
            <fieldset class="dish-total__fieldset dish-total__fieldset_toggles">
                <toggle-button class="dish-total__toggle-input"
                    :color="toggleColors"
                    v-model="modeModel"
                    :width="130"
                    :labels="modeToggleLabels"
                />
            </fieldset>
        </form>
    </section>
</template>

<script>
    import { mapMutations, mapState } from "vuex";
    import DaterangePicker from "@/components/DaterangePicker";

    export default {
        name: "DishTotalSettings",
        components: {
            DaterangePicker,
        },
        computed: {
            ...mapState({
                reportDaterange: state => state.report.daterange,
                compareDaterange: state => state.dish.compareDaterange,
                modeId: state => state.dish.modeId,
            }),
            reportDaterangeModel: {
                get() {
                    return this.reportDaterange;
                },
                set(daterange) {
                    this.setReportDaterange(daterange);
                }
            },
            compareDaterangeModel: {
                get() {
                    return this.compareDaterange;
                },
                set(value) {
                    this.setDishParameter({ parameter: "compareDaterange", value });
                }
            },
            modeModel: {
                get() {
                    return this.modeId === "compareOtherPeriod";
                },
                set(isCompareOtherPeriod) {
                    this.setDishParameter({
                        parameter: "modeId",
                        value: isCompareOtherPeriod ? "compareOtherPeriod" : "base"
                    });
                }
            },
            modeToggleLabels() {
                return {
                    unchecked: "Данные периода",
                    checked: "Сравнение периодов"
                };
            },
            toggleColors() {
                return {
                    unchecked: "#e64d53",
                    checked: "#e0121a"
                };
            },
        },
        methods: {
            ...mapMutations([
                "setReportDaterange",
                "setDishParameter",
            ]),
        }
    };
</script>

<style lang="scss">
    .dish-total__settings {
        margin-bottom: 15px;
    }
    .dish-total__fieldset {
        display: grid;
        grid-auto-columns: auto;
        grid-auto-rows: auto;
        grid-gap: 10px;
        justify-content: start;
        align-content: start;
        justify-items: start;
        align-items: start;

        @include desktop {
            grid-auto-flow: column;
        }

        &_dateranges {
            margin-bottom: 15px;
        }
    }
</style>
